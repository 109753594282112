<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="limit_data"
      class="elevation-1"
      mobile-breakpoint="0"
      hide-default-footer
      disable-pagination
      group-by="bet_type"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>ตารางเลขอั้น</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            v-model="dialog"
            :fullscreen="$vuetify.breakpoint.smAndDown"
            :hide-overlay="$vuetify.breakpoint.smAndDown"
            width="500"
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                เพิ่มเลขอั้น
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 blue lighten-2" dark>
                <span class="text-h5">เพิ่มเลขอั้น</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-select
                        outlined
                        :items="type_items"
                        item-text="text"
                        item-value="value"
                        v-model="selectedType"
                        @change="changedValue"
                      ></v-select>
                    </v-col>

                    <v-col cols="12" class="py-0">
                      <v-text-field label="เลข" outlined type="number" />
                    </v-col>

                    <v-col cols="12" class="pb-0">
                      <v-select outlined :items="prize_item"></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                  ตกลง
                </v-btn>
                <v-btn color="error" text @click="dialog = false">
                  ยกเลิก
                </v-btn>
              </v-card-actions>
            </v-card>
            <!-- end main dialog -->
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5"
                >Are you sure you want to delete this item?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item="{ item }">
        <tr @contextmenu="show($event, item)">
          <!-- <tr @mouseup="show"> -->

          <td class="headcol">
            <v-chip :color="getHpColor(item.BetTypeDetailID)" small dark>{{
              getHpName(item.BetTypeDetailID)
            }}</v-chip>
          </td>
          <td>{{ item.BetNumber }}</td>
          <td>
            {{ getAddon(item.BetTypeDetailID, item.BetNumber, item.Percent) }}
          </td>
          <td>{{ item.Percent }}</td>
          <td>{{ item.TimeBegin }}</td>
          <td>{{ item.TimeEnd }}</td>
        </tr>
      </template>
    </v-data-table>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :nudge-width="150"
    >
      <v-list flat>
        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-magnify</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>เรียกดู</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link>
          <v-list-item-icon>
            <v-icon>mdi-pencil</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>แก้ไข</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="deleteItem()">
          <v-list-item-icon>
            <v-icon>mdi-delete</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ลบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "Hp_Datatable",
  data: () => ({
    row_selected: null,
    // right click
    showMenu: false,
    x: 0,
    y: 0,
    // end right click
    //insert form
    selectedType: { value: 3 },
    type_items: [
      { text: "3 ตัวบน x6 หาง x โต๊ด", value: 3 },
      { text: "3 ตัวบน", value: 1 },
      { text: "2 ตัว บน+ล่าง", value: 4 },
      { text: "3 โต๊ด", value: 2 },
    ],
    prize_item: [
      { text: "จ่ายครึ่ง" },
      { text: "บาทละ" },
      { text: "เปอร์เซ็นต์" },
    ],

    dialog: false,
    dialogDelete: false,

    headers: [
      {
        text: "ชนิด",
        align: "start",
        value: "bet_type",
        width: "70",
      },
      { text: "เลข", value: "number", align: "start", width: "50" },
      { text: "เพิ่มเติม", value: "addition", width: "90" },
      { text: "ราคา", value: "prize", width: "90" },
      { text: "เวลาอั้น", value: "time_begin", width: "150" },
      { text: "เวลาหยุด", value: "time_end" },
    ],
  }),
  mounted() {
    this.fetch_table();
  },
  computed: {
    limit_data() {
      console.log("gettes - >" + this.$store.getters["hp/HPs"]);
      return this.$store.getters["hp/HPs"];
    },
  },
  methods: {
    async fetch_table() {
      this.$store.dispatch("hp/getHPs").then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getHpName: function(hpType) {
      switch (parseInt(hpType)) {
        case 1:
          return "3-บน";
        case 2:
          return "3-โต๊ด";
        case 4:
          return "2-บ+ล";
      }
    },
    getHpColor: function(hpType) {
      switch (parseInt(hpType)) {
        case 1:
          return "red accent-4";
        case 2:
          return "green darken-4";
        case 4:
          return "light-blue darken-4";
      }
    },
    getAddon: function(hpType, betNumber, Prize) {
      let N = Array.from(betNumber);
      if (N.length == 2) {
        if (N[0] == N[1]) return "-";
        return "x 2 หาง";
      } else if (N.length == 3) {
        let add_on = "";
        switch (parseInt(hpType)) {
          case 1:
            if (parseInt(Prize) >= 0) add_on = " + โต๊ด";

            if (N[0] == N[1] && N[0] == N[3]) {
              if (add_on == "") add_on = "-";
            } else if (N[0] != N[1] && N[0] != N[2] && N[1] != N[2]) {
              add_on = "x 6 หาง" + add_on;
            } else {
              add_on = "x 3 หาง" + add_on;
            }
            return add_on;
          case 2:
            return "-";
        }
      }
      // else if(N.length==1){

      // }
    },
    getPrize() {},
    changedValue: function(value) {
      //receive the value selected (return an array if is multiple)
      console.log(value);
    },
    show(e, item) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
        this.row_selected = item;
        //console.log(item);
      });
    },
    // dialog
    deleteItem() {
      // this.editedIndex = this.desserts.indexOf(item)
      // this.editedItem = Object.assign({}, item)
      // this.dialogDelete = true
      console.log(this.row_selected);
      alert(this.row_selected["BetNumber"]);
    },

    deleteItemConfirm() {
      // this.desserts.splice(this.editedIndex, 1)
      // this.closeDelete()
    },

    closeDelete() {
      // this.dialogDelete = false
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem)
      //   this.editedIndex = -1
      // })
    },
  },
};
</script>

<style></style>
