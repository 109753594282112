<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card
          max-width="1050"
          class="mx-auto mt-2"
          v-for="(round_item, i) in group"
          :key="i"
        >
          <v-app-bar color="pink darken-3">
            <!-- <v-app-bar-nav-icon color="white"></v-app-bar-nav-icon> -->
            <!-- 16.00 (30 ต.ค. 64) -->
            <v-toolbar-title class="text-h6 white--text">
              รอบที่ {{ round_item.SetGroup }} เวลา
              {{ round_item.TimeBegin.split(" ")[1].split(":")[0] }}:{{
                round_item.TimeBegin.split(" ")[1].split(":")[1]
              }}
              ({{ getAbbrPeriodThai(round_item.TimeBegin.split(" ")[0]) }})
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>


                <v-list dense>
                <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                >
                    <v-list-item>
                    <v-list-item-icon>
                        <v-icon color="blue">mdi-image</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>แสดงรูปภาพ</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
                </v-list>

            </v-menu>
          </v-app-bar>
          <v-card-title class=" text-h6 deep-purple--text font-weight-bold">
            3 ตัวบน (พร้อมตัวกลับทุกหาง)
          </v-card-title>
          <v-card-text>
            <v-row v-for="(prize_item, i2) in round_item.hp3digit" :key="i2">
              <v-col cols="12">
                <v-chip
                  class="ma-1 font-weight-bold"
                  color="indigo darken-4"
                  outlined
                  v-for="(number_item, i3) in prize_item.Number"
                  :key="i3"
                  @click="alert(123)"
                >
                  {{ number_item.BetNumber }}
                </v-chip>
              </v-col>
              <v-col cols="12" align="left">
                <v-chip
                  class=" font-weight-bold text-decoration-underline"
                  color="indigo darken-4"
                  text-color="white"
                >
                  {{ prize_item.prize }}
                </v-chip></v-col
              >
            </v-row>
          </v-card-text>

          <v-card-title class=" text-h6 deep-purple--text font-weight-bold">
            2 ตัวบน+ล่าง (พร้อมตัวกลับ)
          </v-card-title>
          <v-card-text>
            <v-row v-for="(prize_item, i4) in round_item.hp2digit" :key="i4">
              <v-col cols="12">
                <v-chip
                  class="ma-1 font-weight-bold"
                  color="indigo darken-4"
                  outlined
                  v-for="(number_item, i5) in prize_item.Number"
                  :key="i5"
                  @click="alert(123)"
                >
                  {{ number_item.BetNumber }}
                </v-chip>
              </v-col>
              <v-col cols="12" align="left">
                <v-chip
                  class=" font-weight-bold text-decoration-underline"
                  color="indigo darken-4"
                  text-color="white"
                >
                  {{ prize_item.prize }}
                </v-chip></v-col
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
export default {
  name: "Hp_SplitView",
  mixins: [globalFunctionMixin],
  computed: {
    group() {
      return this.$store.getters["hp/GroupList"];
    },
  },
  mounted() {
    this.fetch_table();
  },
  methods: {
    async fetch_table() {
      let credentials = {
        //   HostID: sessionStorage.getItem("host_id"),
        PeriodDT: sessionStorage.getItem("period"),
      };

      this.$store.dispatch("hp/getHPs", credentials).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style></style>
