<template>
  <div>
    <v-app-bar dense fixed app color="blue darken-4" dark>
      <v-btn icon @click.prevent="back_to_datamenu()">
        <v-icon>mdi-arrow-left-circle-outline</v-icon>
      </v-btn>
      <v-toolbar-title>เลขอั้น งวด {{period_th}}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn icon @click.prevent="view = (view+1)%3">
        <v-icon>mdi-view-agenda-outline</v-icon>
      </v-btn>
    </v-app-bar>

    <HpSplitView v-if="view == 0" />
    <HpViewAll v-if="view == 1" />
    <HpDatatable v-if="view == 2" />
    
    <!-- <v-btn fab dark color="primary" fixed right bottom>
      <v-icon large dark> mdi-plus </v-icon>
    </v-btn> -->
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import HpDatatable from "@/components/Data/HP_Number/Hp_Datatable";
import HpSplitView from "@/components/Data/HP_Number/Hp_SplitView";
import HpViewAll from "@/components/Data/HP_Number/Hp_ViewAll";

export default {
  name: "Hp_Number",
  mixins: [globalFunctionMixin],
  components: {
    HpDatatable,
    HpSplitView,
    HpViewAll,
  },

  data: () => ({
    view: 0,
  }),
  mounted() {
    this.ckLogin();

    // if('ontouchstart' in window || navigator.maxTouchPoints){
    //     alert("I am a touch screen device")
    // }
    //alert(this.getFullPeriodThai(sessionStorage.getItem("period")));
  },
  computed: {
      period_th(){
          return this.getAbbrPeriodThai(sessionStorage.getItem("period"));
      }
  },
  methods: {
    back_to_datamenu() {
      this.$router.push("/Menu_Data");
    },
  },
};
</script>

<style scoped></style>
